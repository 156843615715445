exports.components = {
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-blog-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/templates/blog.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-blog-js" */),
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-job-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/templates/job.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-job-js" */),
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-page-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/templates/page.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-page-js" */),
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-pages-about-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/templates/pages/about.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-pages-about-js" */),
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-pages-blog-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/templates/pages/blog.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-pages-blog-js" */),
  "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-pages-team-js": () => import("./../../../node_modules/@mnemonicorg/gatsby-theme-archive-site/src/templates/pages/team.js" /* webpackChunkName: "component---node-modules-mnemonicorg-gatsby-theme-archive-site-src-templates-pages-team-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jobs-js": () => import("./../../../src/pages/about/jobs.js" /* webpackChunkName: "component---src-pages-about-jobs-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-help-desk-js": () => import("./../../../src/pages/help-desk.js" /* webpackChunkName: "component---src-pages-help-desk-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-our-work-js": () => import("./../../../src/pages/our-work.js" /* webpackChunkName: "component---src-pages-our-work-js" */)
}

